import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { PrimaryBlueButton, SecondaryButton } from "nvent-web/components/Button";
import { LoadingSpinner } from "nvent-web/components/LoadingSpinner";

import Modal, { ModalProps } from "../Modal";

import style from "./EditLevelNameModal.module.scss";

interface EditLevelNameModalProps extends ModalProps {
  currentName: string;
  onConfirm: (name: string) => void;
  loading: boolean;
}

export const EditLevelNameModal = ({
  currentName,
  onConfirm,
  handleClose,
  loading,
  ...modalProps
}: EditLevelNameModalProps) => {
  const [levelName, setLevelName] = useState(currentName);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCancel = () => {
    handleClose();
    setLevelName(currentName);
  };

  useEffect(() => {
    if (modalProps.isOpen && inputRef.current) {
      inputRef.current.select();
    }
  }, [modalProps.isOpen]);

  return (
    <Modal {...modalProps} hasCloseButton handleClose={handleCancel}>
      <h3 className={style.title}>
        <FormattedMessage id="editLevelNameModal.title" />
      </h3>
      <input
        ref={inputRef}
        type="text"
        name="levelName"
        onChange={(e) => setLevelName(e.target.value)}
        className={style.input}
        value={levelName}
        autoFocus
      />
      <div className={style.actions}>
        <SecondaryButton className={style.cancelBtn} onClick={handleCancel} disabled={loading}>
          <FormattedMessage id="actions.cancel" />
        </SecondaryButton>
        <PrimaryBlueButton
          className={classNames(style.yesBtn, { [style.loading]: loading })}
          onClick={() => onConfirm(levelName)}
          disabled={loading}
        >
          {loading ? <LoadingSpinner small /> : <FormattedMessage id="actions.yes" />}
        </PrimaryBlueButton>
      </div>
    </Modal>
  );
};
