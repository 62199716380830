import classNames from "classnames";
import Downshift from "downshift";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { ReactComponent as ThreeDotsIcon } from "nvent-web/assets/svg/three-dots.svg";
import { LoadingSpinner } from "nvent-web/components/LoadingSpinner";

import style from "./ContextMenu.module.scss";

export type ContextMenuAction =
  | {
      type: "link";
      to: string;
      labelId: string;
      isDisabled?: boolean;
    }
  | {
      type: "button";
      labelId: string;
      onClick: (closeMenu?: () => void) => void;
      isDisabled?: boolean;
      isLoading?: boolean;
      className?: string;
    };

type ContextMenuProps = {
  buttonClassName?: string;
  actions: ContextMenuAction[];
};

export const ContextMenu = (props: ContextMenuProps) => {
  const { actions, buttonClassName } = props;

  return (
    <Downshift>
      {({ isOpen, toggleMenu, closeMenu }) => (
        <div className={style.container}>
          <button className={classNames(style.button, buttonClassName)} onClick={() => toggleMenu()}>
            <ThreeDotsIcon width="20" height="4" />
          </button>
          {isOpen && (
            <ul className={style.itemListContainer}>
              {actions.map((action) => {
                if (action.type === "link") {
                  return (
                    <Link to={action.to} className={style.linkItem} key={action.labelId}>
                      <FormattedMessage id={action.labelId} />
                    </Link>
                  );
                } else {
                  return (
                    <button
                      key={action.labelId}
                      onClick={async () => await action.onClick(closeMenu)}
                      disabled={action.isDisabled || action.isLoading}
                      className={classNames(style.buttonItem, action.isDisabled && style.disabled, action.className)}
                    >
                      {action.isLoading ? <LoadingSpinner small /> : <FormattedMessage id={action.labelId} />}
                    </button>
                  );
                }
              })}
            </ul>
          )}
        </div>
      )}
    </Downshift>
  );
};
