import classNames from "classnames";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as EditIcon } from "nvent-web/assets/svg/edit.svg";
import { ReactComponent as OpenIcon } from "nvent-web/assets/svg/open.svg";
import ProgressFull from "nvent-web/components/Progress/FullProgress";
import { Room } from "nvent-web/types/Room";

import { CommissionedLabel } from "../CommissionedLabel";

import style from "./RoomItemCard.module.scss";

type RoomItemCardCommonProps = {
  data: Room;
  index?: number;
  levelId?: number;
  children?: ReactNode;
  isProjectFinished: boolean;
  nestLevelIndex: number;
  link: string;
};

type RoomItemCardProps =
  | (RoomItemCardCommonProps & {
      onEdit: () => void;

      isReadOnly?: false;
    })
  | (RoomItemCardCommonProps & {
      isReadOnly: true;
    });

export const RoomItemCard = (props: RoomItemCardProps) => {
  const {
    data: { name, progress, finishedAt },
    index = 0,
    levelId,
    nestLevelIndex,
    isProjectFinished,
    isReadOnly,
    children,
    link,
  } = props;

  const isRoomCommissioned = Boolean(finishedAt);

  return (
    <li
      className={classNames(
        style.wrapper,
        { [style.nestedRoom]: Boolean(levelId) },
        nestLevelIndex % 2 !== 0 && style.dark
      )}
    >
      {!isProjectFinished && (
        <ProgressFull
          progress={progress}
          borderRadius={index === 0 ? "top" : "none"}
          className={{ wrapper: style.progress }}
          isDisabled={isRoomCommissioned}
        />
      )}
      <div className={style.content}>
        <div className={style.header}>
          <div className={style.info}>
            <div className={style.titleWrapper}>
              <Link to={link}>
                <h4 className={classNames(style.title, isRoomCommissioned && style.disabled)}>{name}</h4>
              </Link>
              {!isReadOnly && (
                <button className={style.editButton} onClick={props.onEdit}>
                  <EditIcon />
                </button>
              )}
            </div>
          </div>
          <div className={style.contextMenus}>
            {isRoomCommissioned && !isProjectFinished && <CommissionedLabel />}
            <Link to={link} className={classNames(style.open, style.link)}>
              <OpenIcon />
            </Link>
            {children}
          </div>
        </div>
      </div>
    </li>
  );
};
