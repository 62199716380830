import { inject } from "mobx-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

import { CopyAction } from "nvent-web/components/Actions/CopyAction";
import { GetPDFAction } from "nvent-web/components/Actions/GetPDFAction";
import { RemoveAction } from "nvent-web/components/Actions/RemoveAction";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import ProjectCard from "nvent-web/components/ProjectCard";
import { useGenerateReport } from "nvent-web/hooks/useGenerateReport";
import Api from "nvent-web/services/Api";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { ProjectsStore } from "nvent-web/stores/Projects";
import { Project } from "nvent-web/types/Project";
import { STOP_POLLING_STATUSES } from "nvent-web/utils/polling";

import { ArchiveAction } from "../Actions/ArchiveAction";
import { GetQuickReportAction } from "../Actions/GetQuickReportAction";

export interface ProjectListItemProps {
  project: Project;
}

export interface ProjectListItemInnerProps extends ProjectListItemProps {
  projects: ProjectsStore;
  notifications: NotificationsStore;
  api: Api;
}

const ProjectListItemInner = ({ project, projects, notifications, api }: ProjectListItemInnerProps) => {
  const [isArchiveConfirmationOpen, setIsArchiveConfirmationOpen] = useState(false);
  const [isRemoveConfirmationOpen, setIsRemoveConfirmationOpen] = useState(false);

  const { generateReport: generateCommissionReport, isGenerateReportSubmitting: isGenerateCommissionReportSubmitting } =
    useGenerateReport({
      id: project.id,
      notificationsStore: notifications,
      fetchReportStatusCallback: api.projects.fetchCommissionReportStatus.bind(api.projects),
      generateReportCallback: api.projects.generateCommissionReport.bind(api.projects),
      downloadReportCallback: api.projects.downloadCommissionReportV3.bind(api.projects),
      isBlobResponse: true,
      stopPollingStatuses: STOP_POLLING_STATUSES,
      pollingInterval: 5000,
    });

  const { generateReport: generateQuickReport, isGenerateReportSubmitting: isGenerateQuickReportSubmitting } =
    useGenerateReport({
      id: project.id,
      notificationsStore: notifications,
      fetchReportStatusCallback: api.projects.fetchQuickReportStatus.bind(api.projects),
      generateReportCallback: api.projects.generateQuickReport.bind(api.projects),
      isBlobResponse: false,
      stopPollingStatuses: STOP_POLLING_STATUSES,
      pollingInterval: 2000,
    });

  const copy = () => {
    projects.copyProject(project.id);
  };

  const archive = async () => {
    await projects.archiveProject(project.id);
    setIsArchiveConfirmationOpen(false);
    projects.getLocalState(project.id).toggleExpanded(false);
  };

  const remove = async () => {
    await projects.removeProject(project.id);
    setIsRemoveConfirmationOpen(false);
  };

  const { isExpanded, toggleExpanded } = projects.getLocalState(project.id);

  const archiveElement = project.finished ? (
    <ArchiveAction
      onClick={() => setIsArchiveConfirmationOpen(true)}
      key="archive"
      disabled={Boolean(project.archivedAt)}
    />
  ) : undefined;

  const removeElement = project.deletable ? (
    <RemoveAction onClick={() => setIsRemoveConfirmationOpen(true)} key="remove" />
  ) : undefined;

  const getReportElement = project.finished ? (
    <GetPDFAction onClick={generateCommissionReport} key="report" loading={isGenerateCommissionReportSubmitting} />
  ) : (
    <GetQuickReportAction key="report" onClick={generateQuickReport} loading={isGenerateQuickReportSubmitting} />
  );

  const actions = [archiveElement, removeElement, <CopyAction onClick={copy} key="copy" />, getReportElement].filter(
    (element) => Boolean(element)
  );

  return (
    <>
      <ProjectCard project={project} actions={actions} toggleExpanded={toggleExpanded} isExpanded={isExpanded} />

      <ConfirmationModal
        isOpen={isArchiveConfirmationOpen}
        handleClose={() => setIsArchiveConfirmationOpen(false)}
        center
        title={<FormattedMessage id={"projects.archiveModal.title"} />}
        description={<FormattedMessage id={"projects.archiveModal.description"} />}
        onCancel={() => setIsArchiveConfirmationOpen(false)}
        onConfirm={archive}
      />
      <ConfirmationModal
        isOpen={isRemoveConfirmationOpen}
        handleClose={() => setIsRemoveConfirmationOpen(false)}
        center
        title={<FormattedMessage id={"projects.removeModal.title"} />}
        description={<FormattedMessage id={"projects.removeModal.description"} />}
        onCancel={() => setIsRemoveConfirmationOpen(false)}
        onConfirm={remove}
      />
    </>
  );
};

export const ProjectListItem = compose<ProjectListItemInnerProps, ProjectListItemProps>(
  inject("projects", "notifications", "api")
)(ProjectListItemInner);
