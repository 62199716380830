import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import Api from "nvent-web/services/Api";
import * as logger from "nvent-web/services/logger";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { Level } from "nvent-web/types/Level";
import { Room } from "nvent-web/types/Room";

type NestedElementsFetchStatus = "idle" | "fetching" | "fetched";

export const useManageNestedElements = ({
  api,
  id,
  notifications,
  projectId,
  key,
}: {
  api: Api["levels"] | Api["admin"]["levels"];
  id: number;
  notifications: NotificationsStore;
  projectId: number;
  key: string;
}) => {
  const [areNestedElementsOpen, setAreNestedElementsOpen] = useState(false);
  const [nestedElementsFetchStatus, setNestedElementsFetchStatus] = useState<NestedElementsFetchStatus>("idle");
  const [nestedLevels, setNestedLevels] = useState<Level[]>([]);
  const [nestedRooms, setNestedRooms] = useState<Room[]>([]);

  const fetchNestedLevels = useCallback(async () => {
    try {
      setNestedElementsFetchStatus("fetching");
      const { data } = await api.getOne({ projectId, levelId: id });
      setNestedLevels(data.levels);
      setNestedRooms(data.rooms);
      setNestedElementsFetchStatus("fetched");
    } catch (err) {
      logger.error(err);
      notifications.createError(<FormattedMessage id="error.getLevelNestedElementsFailed" />);
      setNestedElementsFetchStatus("idle");
    }
  }, [api, id, notifications, projectId]);

  const getStoredExpandedLevelIds = useCallback(() => {
    const expandedLevelsValue = sessionStorage.getItem(key);
    if (!expandedLevelsValue) return [];

    const parsed: string[] = JSON.parse(expandedLevelsValue);
    return parsed.map(Number);
  }, [key]);

  useEffect(() => {
    const fetchMyData = async () => {
      const expandedLevels = getStoredExpandedLevelIds();
      // To maintain the expanded state of nested elements when re-entering the page,
      // we need to fetch the nested elements on component mount.
      // If the current level is expanded and nested elements have not yet been fetched, trigger the fetch.
      if (expandedLevels?.includes(id) && nestedElementsFetchStatus === "idle") {
        await fetchNestedLevels();
        setAreNestedElementsOpen(true);
      }
    };

    fetchMyData();
  }, [fetchNestedLevels, id, nestedElementsFetchStatus, getStoredExpandedLevelIds]);

  const openNestedElements = async () => {
    if (nestedElementsFetchStatus === "idle") {
      // we fetch nested elements on element expand
      // if nested elements have not been fetched yet
      await fetchNestedLevels();
    }
    setAreNestedElementsOpen(true);
  };

  const toggleNestedElements = () => {
    let expandedLevels = getStoredExpandedLevelIds();

    if (areNestedElementsOpen) {
      setAreNestedElementsOpen(false);
      // we want to remove the level from the list of expanded levels and all its nested levels
      expandedLevels = expandedLevels.filter(
        (itemId) => itemId !== id && !nestedLevels.map((level) => level.id).includes(itemId)
      );
    } else {
      openNestedElements();
      // we add level to expanded level list
      expandedLevels.push(id);
    }

    sessionStorage.setItem(key, JSON.stringify(expandedLevels));
  };

  return {
    areNestedElementsOpen,
    toggleNestedElements,
    nestedRooms,
    nestedLevels,
    isFetching: nestedElementsFetchStatus === "fetching",
  };
};
