import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { PrimaryBlueButton, PrimaryGreenButton } from "nvent-web/components/Button";
import { LoadingSpinner } from "nvent-web/components/LoadingSpinner";
import ConfirmationModal from "nvent-web/components/Modal/ConfirmationModal";
import { useGenerateReport } from "nvent-web/hooks/useGenerateReport";
import * as logger from "nvent-web/services/logger";
import RoomsResource from "nvent-web/services/resources/RoomResource";
import { NotificationsStore } from "nvent-web/stores/Notifications";
import { RoomsStore } from "nvent-web/stores/Rooms";
import { ROOM_STOP_POLLING_STATUSES } from "nvent-web/utils/polling";

import style from "./RoomCommissionActions.module.scss";

type RoomCommissionActionsProps = {
  isFinished: boolean;
  canBeCommissioned: boolean;
  projectId: number;
  roomId: number;
  roomsStore: RoomsStore;
  api: RoomsResource;
  notifications: NotificationsStore;
};

export const RoomCommissionActions = ({
  isFinished,
  api,
  notifications,
  roomsStore,
  roomId,
  projectId,
  canBeCommissioned,
}: RoomCommissionActionsProps) => {
  const [isCommissionSubmitting, setIsCommissionSubmitting] = useState(false);
  const [isCommissionModalOpen, setIsCommissionModalOpen] = useState(false);

  const handleError = (err: unknown, translationId: string) => {
    logger.error(err);
    notifications.createError(<FormattedMessage id={translationId} />);
  };

  const { isGenerateReportSubmitting, generateReport } = useGenerateReport({
    notificationsStore: notifications,
    id: roomId,
    fetchReportStatusCallback: api.fetchReportStatus.bind(api),
    generateReportCallback: api.generateReport.bind(api),
    isBlobResponse: false,
    stopPollingStatuses: ROOM_STOP_POLLING_STATUSES,
    pollingInterval: 1000,
  });

  const commissionRoom = async () => {
    try {
      setIsCommissionSubmitting(true);
      await api.commission(roomId);
    } catch (err) {
      handleError(err, "error.roomCommissionFailed");
      setIsCommissionSubmitting(false);
      return;
    }

    try {
      roomsStore.getRoom(projectId, roomId);
    } catch (err) {
      handleError(err, "error.roomDetailsFetchFailed");
      setIsCommissionSubmitting(false);
      return;
    }
    notifications.createSuccess(<FormattedMessage id="rooms.messages.commissioned" />);
    setIsCommissionSubmitting(false);
    setIsCommissionModalOpen(false);
  };

  return (
    <>
      {isFinished ? (
        <PrimaryBlueButton
          className={style.actionButton}
          onClick={generateReport}
          disabled={isGenerateReportSubmitting}
        >
          {isGenerateReportSubmitting ? <LoadingSpinner small /> : <FormattedMessage id="actions.getPDF" />}
        </PrimaryBlueButton>
      ) : (
        canBeCommissioned && (
          <PrimaryGreenButton
            className={style.actionButton}
            onClick={() => setIsCommissionModalOpen(true)}
            disabled={isCommissionSubmitting}
          >
            {isCommissionSubmitting ? <LoadingSpinner small /> : <FormattedMessage id="actions.commissioning" />}
          </PrimaryGreenButton>
        )
      )}
      <ConfirmationModal
        center
        isOpen={isCommissionModalOpen}
        handleClose={() => setIsCommissionModalOpen(false)}
        title={<FormattedMessage id="room.commissionConfirmationModal.title" />}
        description={<FormattedMessage id="room.commissionConfirmationModal.description" />}
        onCancel={() => setIsCommissionModalOpen(false)}
        loading={isCommissionSubmitting}
        onConfirm={commissionRoom}
      />
    </>
  );
};
